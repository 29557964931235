import {AUTOMATION_HEIGHT} from 'App';
import {useFormikContext} from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {useSelector} from 'react-redux';
import styled from 'styled-components';

const Container = styled.div`
  cursor: move;
  position: absolute;
  right: 0;
  text-align: right;
  padding: 0 .2rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: #fff;;
  background: ${(props) => props.error ? '#ff0000' : 'green'}
`;

const ContainerSupport = styled.div`
  position: absolute;
  right: 0;
  text-align: right;
  padding: 0 1.4rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: #fff;;
  background: black;
`;

export const AdditionalSupport = () => {
  const automationWingError = localStorage.getItem('automationErr');
  const formik = useFormikContext();
  const scale = useSelector((state) => state.app.scale);
  // const { t } = useTranslation();
  const {gateType, automationWing} = formik.values.gate;
  const supported = ['gateTilting', 'singleLeafGate', 'foldingGate', 'nonSymmetricalGate'].includes(gateType);
  if (!supported || automationWing?.length !== 3 || automationWingError !== '') return null;
  console.log(formik.values.gate)
  
  return (
    <>
      <ContainerSupport style={{
        height: `${automationWing[1] / scale}px`,
        bottom: `${(automationWing[0] + 40) / scale}px`
      }}
      >
        {/* <span>{t('automationIndustrial')}: </span> */}
        <span>{automationWing[1]} mm</span>
      </ContainerSupport>
      <ContainerSupport style={{
        height: `${AUTOMATION_HEIGHT / scale}px`,
        bottom: `${(automationWing[0] - 40) / scale}px`,
        background: 'green'
      }}
      >
        {/* <span>{t('automationIndustrial')}: </span> */}
        <span>{AUTOMATION_HEIGHT} mm</span>
      </ContainerSupport>
      <ContainerSupport style={{
        height: `${automationWing[2] / scale}px`,
        bottom: `${(automationWing[0] - 40 - automationWing[2]) / scale}px`
      }}
      >
        {/* <span>{t('automationIndustrial')}: </span> */}
        <span>{automationWing[2]} mm</span>
      </ContainerSupport>
    </>
)};

const Automation = () => {
  const formik = useFormikContext();
  const scale = useSelector((state) => state.app.scale);
  const { t } = useTranslation();
  const {gateType, automation} = formik.values.gate;
  const bottom = automation.height / scale;
  const supported = ['gateTilting', 'singleLeafGate', 'foldingGate', 'nonSymmetricalGate'].includes(gateType);
  if (!supported || !automation.range || !automation.checked) return null;
  return <Container style={{
    height: `${AUTOMATION_HEIGHT / scale}px`,
    bottom: `${bottom}px`
  }} error={formik.touched?.gate?.automation?.height && formik.errors?.gate?.automation?.height}>
    {gateType === 'singleLeafGate' && (
      <span>{t('autoVisSingleLeafGate')}</span>
    )}
    {gateType !== 'singleLeafGate' && (
      <span>{t('autoVis')}</span>
    )}
    <span>H: {automation.height} mm</span>
  </Container>
};

export default Automation;
