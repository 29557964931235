import React, {useContext} from 'react';

import {hinges} from 'fenceTypesConstants';
import {AUTOMATION_HEIGHT} from 'App';

import {humanisePlateWord} from '../commonFunctions';
import NonStandard from '../NonStandard';
import Context from '../context';
import { useTranslation } from 'react-i18next';

const GateCommon = () => {
  const {
    values,
    gateRodsAmount: rodsAmount,
    gateNonStandardPlates: nonStandardPlates,
    gateSmallPlates: smallPlates,
    gateStandardPlates: standardPlates,
  } = useContext(Context);
  const {amount, chosen, automation, gateType, automationWing, width} = values.gate;
  if (!chosen) return null;
  const totalStandardPlates = standardPlates * amount;
  const totalSmallPlates = smallPlates * amount;
  const totalNonStandardPlates = nonStandardPlates * amount;
  const totalRodsAmount = rodsAmount * amount;
  const showHinges = ['gateTilting', 'singleLeafGate', 'foldingGate', 'nonSymmetricalGate'].includes(gateType);
  const showSection = ['singleLeafGate', 'foldingGate', 'nonSymmetricalGate'].includes(gateType);
  const automationSupported = ['gateTilting', 'singleLeafGate', 'foldingGate'].includes(gateType);
  const { t } = useTranslation();

  const getIsIndustrial = () => {
    if (gateType === 'gateTilting' && width > 5999) {
      return true;
    }
    if (gateType === 'singleLeafGate' && width > 2999) {
      return true;
    }
    return false;
  };

  const isIndustrial = getIsIndustrial();
  return (
    <>
      {showSection && (
        <p>
          <b>{t('cSectionsAmount')}:</b> {totalRodsAmount} szt
        </p>
      )}
      <p>
        <b>{t('gatesAmount')}</b> {amount} szt
      </p>
      <p>
        <b>{t('total302PlateAmount')}:</b> {totalStandardPlates} szt
      </p>
      <p>
        <b>{t('totalTop222PlateAmount')}:</b> {totalSmallPlates} szt
      </p>
      <NonStandard nonStandardPlates={totalNonStandardPlates}/>
      <p><b>{t('color')}:</b> {values.color}</p>
      {showHinges && <p><b>{t('chinges')}:</b> {hinges[values.gate.hinges]}</p>}
      {automationSupported && !isIndustrial && <p><b>{t('automation')}:</b> {automation.checked ? t('yes') : t('no')}</p>}
      {automationSupported && isIndustrial && <p><b>{t('automationIndustrial')}:</b> {automation.checked ? t('yes') : t('no')}</p>}
      {automationSupported && isIndustrial && automation.checked && <p>
        <b>{t('automationPlacement')}:</b> {automation.height} mm
      </p>}
      {automationWing?.length === 3 && (
        <>
          <p>
            <b>{t('underAutomationWing')}</b>{automationWing[2]} mm
          </p>
          <p>
            <b>{t('overAutomationWing')}</b>{automationWing[1]} mm
          </p>
        </>
      )}
    </>
  );
};

export default GateCommon;
