import React from 'react';
import {FormikProvider, useFormik} from 'formik';
import {useDispatch} from 'react-redux';
import ReactDOMServer from 'react-dom/server';
import * as yup from 'yup';
import {setLocale} from 'yup';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import * as Sentry from '@sentry/react';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import generatePdf from 'Pdf/generatePdf';
import Pdf from 'Pdf/Pdf';
import createPdfVariables from 'Pdf/commonFunctions';
import wrapPdfWithHtml from 'Pdf/wrapPdfWithHtml';
import Footer from './Footer';
import GettingStarted from './GettingStarted/GettingStarted';
import Header from './Header';
import Project from './Project/Project';
import FinishForm from './FinishForm/FinishForm';
import Terms from 'Terms';
import TermsModule from 'TermsModule';
import i18next from './Translation/config';
import { useTranslation } from 'react-i18next';

// AUTOMATION_HEIGHT is the height in mm of automation machine/module
export const AUTOMATION_HEIGHT = 80;
export const AUTOMATION_BREAKPOINT = 1400; // mm
export const BOTTOM_FRAME = 80;
export const STANDARD_PLATE_HEIGHT = 302;
export const SMALL_PLATE_HEIGHT = 222;
export const SUPPLEMENT_SPACE_PLATE_MIN_HEIGHT = 60;

export const OPTION_P302 = 'P302';
export const OPTION_P302_WITH_MODULE = 'P302_module';

export const FENCE_TYPE_GATE_SELF_SUPPORTING = 'gateSelfSupporting';
export const FENCE_TYPE_GATE = 'gate';
export const FENCE_TYPE_GATE_TILTING = 'gateTilting';
export const FENCE_TYPE_SINGLE_LEAF_GATE = 'singleLeafGate';
export const FENCE_TYPE_FOLDING_GATE = 'foldingGate';
export const FENCE_TYPE_GATEWAY = 'gateway';
export const FENCE_TYPE_SPAN = 'span';
export const FENCE_TYPE_NON_SYMMETRICAL = 'nonSymmetricalGate';

export const projectKeysHuman = {
  [FENCE_TYPE_SPAN]: 'przęsła',
  [FENCE_TYPE_GATEWAY]: 'furtki',
  [FENCE_TYPE_GATEWAY]: 'bramy',
};

export const fenceTypesHuman = {
  [FENCE_TYPE_GATE_SELF_SUPPORTING]: 'Brama samonośna',
  [FENCE_TYPE_GATE_TILTING]: 'Brama uchylna',
  [FENCE_TYPE_GATEWAY]: 'Furtka',
  [FENCE_TYPE_SPAN]: 'Przęsło',
  [FENCE_TYPE_SINGLE_LEAF_GATE]: 'Brama jednoskrzydłowa',
  [FENCE_TYPE_FOLDING_GATE]: 'Brama łamana',
  [FENCE_TYPE_NON_SYMMETRICAL]: 'Brama niesymetryczna',
};

const minErr = i18next.language === 'pl' ? 'Minimalny wymiar to ${min}mm.': 'Minimum dimension is ${min}mm.';
const maxErr = i18next.language === 'pl' ? 'Maksymalny wymiar to ${max}mm.': 'Maximum dimension is ${max}mm.';

setLocale({
  mixed: {
    required: i18next.language === 'pl' ? 'Wartość jest wymagana.': 'Value is required.',
  },
  number: {
    min: i18next.language === 'pl' ? 'Minimalna wartość to ${min}mm.': 'Minimum value is ${min}.',
    max: i18next.language === 'pl' ? 'Maksymalna wartość to ${max}mm.': 'Maximum value is ${max}.',
  },
  string: {
    min: i18next.language === 'pl' ? 'Minimalna długość to ${min}mm.': 'Minimum length is ${min}.',
    max: i18next.language === 'pl' ? 'Maksymalna długość to ${max}mm.': 'Maximum length is ${max}.',
  },
});

const baseSchema = {
  space: yup.mixed().when('chosen', {
    is: true,
    then: yup.number().min(0).max(100).required(),
    otherwise: null,
  }),
  amount: yup.mixed().when('chosen', {
    is: true,
    then: yup.number().min(1).max(100).required(),
    otherwise: null,
  }),
  height: yup.mixed().when('chosen', {
    is: true,
    then: yup.number().min(1000, minErr).max(2500, maxErr).required(),
    otherwise: null,
  }),
};

const spanSchema = yup.object().shape({
  ...baseSchema,
  width: yup.mixed().when('chosen', {
    is: true,
    then: yup.number().min(315, minErr).max(2500, maxErr).required(),
    otherwise: null,
  }),
  height: yup.mixed().when('chosen', {
    is: true,
    then: yup.number().min(302, minErr).max(2300, maxErr).required(),
    otherwise: null,
  }),
});

const gatewaySchema = yup.object().shape({
  ...baseSchema,
  height: yup.mixed().when('chosen', {
    is: true,
    then: yup.number().min(1000, minErr).max(2300, maxErr).required(),
    otherwise: null,
  }),
  width: yup.mixed().when('chosen', {
    is: true,
    then: yup.number().min(315, minErr).max(1500, maxErr).required(),
    otherwise: null,
  }),
  hinges: yup.mixed().when('chosen', {
    is: true,
    then: yup.string().required(),
    otherwise: null,
  }),
});

// TODO: WYLACZENIE AUTOMATYKI 29.01.2025
// const automationErr = i18next.language === 'pl' ? 'Błędna wysokość automatyki' : 'Incorrect automation height';

const gateSchema = yup.object().shape({
  ...baseSchema,
  height: yup.mixed().when(['chosen', 'gateType'], (chosen, gateType, schema) => {
    if (!chosen || !gateType) return schema;
    if (gateType === 'gateTilting' || gateType === 'singleLeafGate' || gateType === 'foldingGate' || gateType === 'nonSymmetricalGate') {
      return yup.number().min(302, minErr).max(2300, maxErr).required();
    } else if (gateType === 'gateSelfSupporting') {
      return yup.number().min(1000, minErr).max(2300, maxErr).required();
    } else {
      throw new Error('cc63ccc7-b768-46f3-b3e0-6b7aa9c49c44: unknown gateType');
    }
  }),
  width: yup.mixed().when(['chosen', 'gateType'], (chosen, gateType, schema) => {
    if (!chosen || !gateType) return schema;
    if (gateType === 'gateTilting') {
      return yup.number().min(302, minErr).max(7500, maxErr).required();
    } else if (gateType === 'foldingGate') {
      return yup.number().min(302, minErr).max(5999, maxErr).required();
    } else if (gateType === 'singleLeafGate') {
      return yup.number().min(302, minErr).max(3750, maxErr).required();
    } else if (gateType === 'gateSelfSupporting') {
      return yup.number().min(302, minErr).max(7500, maxErr).required();
    } else if (gateType === 'nonSymmetricalGate') {
    } else {
      throw new Error('520769b6-6591-45df-a266-8b8fc95b504c: unknown gateType');
    }
  }),
  leftWingWidth: yup.mixed().when(['chosen', 'gateType'], (chosen, gateType, schema) => {
    if (!chosen || !gateType) return schema;
    if (gateType === 'nonSymmetricalGate') {
      return yup.number().min(500, minErr).max(3750, maxErr).required();
    }
  }),
  rightWingWidth: yup.mixed().when(['chosen', 'gateType'], (chosen, gateType, schema) => {
    if (!chosen || !gateType) return schema;
    if (gateType === 'nonSymmetricalGate') {
      return yup.number().min(500, minErr).max(3750, maxErr).required();
    }
  }),
  // TODO: WYLACZENIE AUTOMATYKI 29.01.2025
  // automation: yup.mixed().when('chosen', {
  //   is: true,
  //   then: yup.object().shape({
  //     range: yup.object().nullable(),
  //     height: yup.mixed().when('range', (range, schema) => {
  //       return schema.test({
  //         name: 'automation.height',
  //         message: automationErr,
  //         test: (v) => {
  //           return range ? v >= range.min && v <= range.max : true;
  //         },
  //       });
  //     })
  //   }),
  //   otherwise: null,
  // }),
  hinges: yup.mixed().when(['chosen', 'gateType'], (chosen, gateType, schema) => {
    if (!chosen || !gateType) return schema;
    if (gateType === 'gateTilting' || gateType === 'singleLeafGate' || gateType === 'nonSymmetricalGate') {
      return yup.string().required();
    } else if (gateType === 'gateSelfSupporting' || gateType === 'foldingGate') {
    } else {
      throw new Error('b8996dfa-8cc9-4b56-80e2-87e8fc3463ec: unknown gateType');
    }
  }),
  mountingStrip: yup.string().oneOf(['0', '1', '2']),
});

const moduleHeight = yup.object().shape({
  moduleHeight: yup.mixed().when('chosen', {
    is: true,
    then: yup.number().min(300, minErr).max(1000, maxErr).required(),
    otherwise: null,
  }),
});

const wrongEmail = i18next.language === 'pl' ? 'Wprowadź poprawny e-mail.' : 'Please enter a valid email address';

const validationSchema = yup.object().shape({
  email: yup.string().email(wrongEmail).required(),
  projectName: yup.string().min(3).required(),
  span: yup.mixed().when('option', {
    is: 'P302',
    then: spanSchema,
    // P302_module
    otherwise: yup.object().concat(spanSchema).concat(moduleHeight),
  }),
  gateway: yup.mixed().when('option', {
    is: 'P302',
    then: gatewaySchema,
    // P302_module
    otherwise: yup.object().concat(gatewaySchema).concat(moduleHeight),
  }),
  gate: yup.mixed().when('option', {
    is: 'P302',
    then: gateSchema,
    // P302_module
    otherwise: yup.object().concat(gateSchema).concat(moduleHeight),
  }),
});

export const changeModel = (formik) => {
  formik.setFieldValue('termsAccepted', false);
  formik.setFieldValue('option', '');
  formik.setFieldValue('color', '');
  formik.setFieldValue('moduleColor', '');
  formik.setFieldValue('span.chosen', false);
  formik.setFieldValue('gateway.chosen', false);
  formik.setFieldValue('gate.chosen', false);
}

export const GATE_AUTOMATION_KEY = 'automation';

const createBody = ({values, url, vars}) => {
  return {
    email: values.email,
    pdfUrl: url,
    project: {
      ...values,
      id: uuidv4(),
      name: values.projectName,
      span: {
        ...values.span,
        elements: vars.spanElements,
      },
      gateway: {
        ...values.gateway,
        elements: vars.gatewayElements,
      },
      gate: {
        ...values.gate,
        elements: vars.gateElements,
      },
    },
  }
}

const App = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const formik = useFormik({
    initialValues: {
      termsAccepted: false,
      option: '', // P302 P302_module
      email: '',
      projectName: '',
      color: '', // Grafitowy
      moduleColor: '', // 8017
      span: {
        chosen: false,
        width: 0, // 1000
        height: 0, // 1036
        space: 0, // 9
        amount: 1,
        moduleHeight: 0,
        supplementSpace: 0,
        rodsStructure: [],
        renderDesign: false,
      },
      gateway: {
        chosen: false,
        width: 0, // 1200
        widthInit: 0,
        height: 0, // 1790
        heightInit: 0,
        space: 0, // 39
        amount: 1,
        hinges: '', // standardHinges
        moduleHeight: 0,
        supplementSpace: 0,
        rodsStructure: [],
        renderDesign: false,
      },
      gate: {
        chosen: false,
        width: 0, // 4000
        widthInit: 0,
        leftWingWidth: 0,
        rightWingWidth: 0,
        height: 0, // 2000
        heightInit: 0,
        space: 0, // 21
        gateType: '', // gateTilting gateSelfSupporting singleLeafGate
        amount: 1,
        hinges: '', // standard
        moduleHeight: 0,
        supplementSpace: 0,
        rodsStructure: [],
        renderDesign: false,
        mountingStrip: '0', // 0 1 2
        [GATE_AUTOMATION_KEY]: {
          checked: false,
          range: null,
          height: null,
        },
        automationWing: '',
        automationWingError: '',
      },
    },
    validationSchema,
    onSubmit: async (values) => {
      dispatch({type: 'SAVING_PROJECT'});
      const vars = await createPdfVariables(values);
      const pdf = ReactDOMServer.renderToStaticMarkup(
        <Pdf
          spanElements={vars.spanElements}
          spanRender={vars.spanRender}
          gatewayElements={vars.gatewayElements}
          gatewayRender={vars.gatewayRender}
          gateElements={vars.gateElements}
          gateRender={vars.gateRender}
          values={values}
        />,
      );
      const html = wrapPdfWithHtml(pdf);
      try {
        const url = await generatePdf(html);
        const body = createBody({values, url, vars});
        const {data} = await axios.post(`https://api.my.konsport.com/xps/projects`, body)
        dispatch({type: 'API_SUCCESS', message: data.message})
      } catch (e) {
        Sentry.captureException(e);
        dispatch({type: 'API_ERROR', message: t('errorOccured')})
        console.error(e);
      }
    }
  });

  return (
    <FormikProvider value={formik}>
      <form>
        <Header/>
        <GettingStarted/>
        <Terms/>
        <TermsModule/>
        <Project/>
        <FinishForm/>
        <Footer/>
      </form>
    </FormikProvider>
  );
};

export default App;
